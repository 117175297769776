import React, { useState } from "react";
import {
  Box,
  Text,
  Pagination,
  Label,
  LabelGroup,
  StyledOcticon,
  Link,
  BaseStyles,
  useColorSchemeVar,
  BranchName,
} from "@primer/react";
import { MarkGithubIcon } from "@primer/styled-octicons";
import colors from "@primer/primitives";
import processString from "react-process-string";

import ProjectDetails from "./ProjectDetails";

import projects from "./resources/projects.json";

function Projects() {
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage] = useState(3);

  const mutedAccent = useColorSchemeVar({
    light: colors.colors.light.done.emphasis,
    dark: colors.colors.dark.done.emphasis,
    dark_dimmed: colors.colors.dark_dimmed.done.emphasis,
  });

  let config = [
    {
      regex: /<Link href="(.*?[^\\])">(.*?)<\/Link>/gim,
      fn: (key, result) => (
        <Link
          key={key}
          href={result[1]}
          target="_blank"
          style={{ textDecoration: "none" }}
          hoverColor={mutedAccent}
        >
          {result[2]}
        </Link>
      ),
    },
    {
      regex: /<Highlight>(\S+)<\/Highlight>/gim,
      fn: (key, result) => (
        <Text key={key} as="span">
          <BranchName>{result[1]}</BranchName>
        </Text>
      ),
    },
  ];

  let dataProject = projects.filter((id) => id.show);

  let data = dataProject
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map((data) => {
      return (
        <Box
          key={data.id}
          borderColor="border.default"
          borderWidth={1}
          borderStyle="solid"
          p={3}
          mb={3}
        >
          <Text fontWeight="bold">{data.title}</Text>
          {data.description.map((curr, index) => {
            return (
              <Text as="p" key={data.id + index}>
                {processString(config)(curr)}
              </Text>
            );
          })}
          {data.details && <ProjectDetails info={data.details} />}
          <LabelGroup>
            {data.github && (
              <Label variant="accent">
                <Link href={data.github} target="blank">
                  <StyledOcticon icon={MarkGithubIcon} color="accent.fg" />
                </Link>
              </Label>
            )}
            {data.technologies.map((tech, id) => {
              return (
                <Label key={data.id + id} variant="accent">
                  {tech}
                </Label>
              );
            })}
          </LabelGroup>
        </Box>
      );
    });

  return (
    <BaseStyles>
      <Text as="h2">Projects</Text>
      {data}
      <Pagination
        pageCount={Math.ceil(dataProject.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={(evt, page) => {
          evt.preventDefault();
          setCurrentPage(page);
        }}
      />
    </BaseStyles>
  );
}

export default Projects;
