import {
  ThemeProvider,
  BaseStyles,
  Box,
  StyledOcticon,
  Link,
  PageLayout,
  themeGet,
} from "@primer/react";
import { MarkGithubIcon, MailIcon } from "@primer/styled-octicons";
import styled from "styled-components";

import LinkedInLogo from "./LinkedInLogo";
import Projects from "./Projects";
import Career from "./Career";
import ThemeToggle from "./ThemeToggle";

const Application = styled.div`
  background-color: ${themeGet("colors.canvas.default")};
  min-height: 100vh;
  height: 100%;
`;

function App() {
  return (
    <ThemeProvider colorMode="auto" dayScheme="light" nightScheme="dark_dimmed">
      <BaseStyles>
        <Application className="App">
          <PageLayout rowGap="condensed">
            <PageLayout.Header divider="line">
              <Box
                display="grid"
                gridTemplateColumns="repeat(2, auto)"
                gridGap={3}
              >
                <Box style={{ justifySelf: "end" }}>
                  <Link href="https://github.com/McSquanchy" target="blank">
                    <StyledOcticon
                      icon={MarkGithubIcon}
                      color="accent.emphasis"
                      size={32}
                    />
                  </Link>
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns="repeat(2, auto)"
                  gridGap={0}
                >
                  <Box style={{ justifySelf: "start" }}>
                    <Link
                      href="https://www.linkedin.com/in/kbuman/"
                      target="blank"
                    >
                      <LinkedInLogo />
                    </Link>
                  </Box>
                  <Box style={{ justifySelf: "end" }}>
                    <ThemeToggle />
                  </Box>
                </Box>
              </Box>
            </PageLayout.Header>
            <PageLayout.Content>
              <Box>
                <Projects />
              </Box>
              <Box>
                <Career />
              </Box>
            </PageLayout.Content>
            <PageLayout.Footer divider="line">
              <Box
                display="grid"
                gridTemplateColumns="repeat(1,auto)"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Link href="mailto:contact@kbuman.me?subject=Enquiry">
                  <StyledOcticon
                    icon={MailIcon}
                    color="accent.emphasis"
                    size={32}
                  />
                </Link>
              </Box>
            </PageLayout.Footer>
          </PageLayout>
        </Application>
      </BaseStyles>
    </ThemeProvider>
  );
}

export default App;
