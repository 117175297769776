import React from "react";
import ReactFreezeframe from "react-freezeframe";

export default function GifComponent(props) {
  return (
    <ReactFreezeframe
      options={{
        overlay: true,
        trigger: "click",
      }}
      src={props.path}
    />
  );
}
