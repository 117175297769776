import React from "react";
import { Text, Box, Details, useDetails } from "@primer/react";
import GifComponent from "./GifComponent";
import ReactPlayer from "react-player/file";

export default function ProjectDetails(props) {
  const { getDetailsProps } = useDetails({ closeOnOutsideClick: true });
  return (
    <Box mb={3}>
      <Details {...getDetailsProps()}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={3}
          p={3}
        >
          {props.info.images &&
            props.info.images.map((item, index) => {
              return (
                <Box key={index} gridTemplateRows="1.8fr 0.2fr">
                  <Box>
                    <img
                      src={item.avifPath}
                      alt={item.alt}
                      style={{ maxWidth: "100%" }}
                    />
                  </Box>
                  <Box>
                    <Text as="p">{item.description}</Text>
                  </Box>
                </Box>
              );
            })}
        </Box>
        {props.info.gifs && (
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap={3}
            p={3}
          >
            {props.info.gifs.map((item, index) => {
              return (
                <Box key={index} gridTemplateRows="1.8fr 0.2fr">
                  <Box>
                    <ReactPlayer
                      url={item.path}
                      autoPlay
                      width="100%"
                      height="100%"
                      loop
                      config={{
                        file: {
                          attributes: {
                            autoPlay: true,
                            muted: true,
                          },
                        },
                      }}
                      style={{ maxWidth: "100%" }}
                    />

                    {/* <video src={item.path} autoplay loop onClick={this.play} /> */}
                    <GifComponent path={item.path} still={item.still} />
                  </Box>
                  <Box>
                    <Text as="p">{item.description}</Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Details>
    </Box>
  );
}
