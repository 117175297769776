import React, { useState } from "react";
import {
  Text,
  Timeline,
  StyledOcticon,
  ActionMenu,
  ActionList,
  Box,
  useColorSchemeVar,
  Link,
} from "@primer/react";
import { FlameIcon, GitCommitIcon } from "@primer/styled-octicons";
import processString from "react-process-string";
import colors from "@primer/primitives";
import career from "./resources/career.json";

export default function Career() {
  const [currentActive, setCurrentActive] = useState("all");
  const mutedAccent = useColorSchemeVar({
    light: colors.colors.light.done.emphasis,
    dark: colors.colors.dark.done.emphasis,
    dark_dimmed: colors.colors.dark_dimmed.done.emphasis,
  });

  let config = [
    {
      regex: /<Link href="(.*?[^\\])">(.*?)<\/Link>/gim,
      fn: (key, result) => (
        <Link
          key={key}
          href={result[1]}
          target="_blank"
          style={{ textDecoration: "none" }}
          hoverColor={mutedAccent}
        >
          {result[2]}
        </Link>
      ),
    },
    {
      regex: /<br\/>/gim,
      fn: (key, _) => <br key={key} />,
    },
  ];

  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(2, auto)" gridGap={3}>
        <Text as="h2">Career</Text>
        <Box
          style={{
            justifySelf: "flex-end",
          }}
        >
          <Text as="h2">
            <ActionMenu>
              <ActionMenu.Button>Filter</ActionMenu.Button>
              <ActionMenu.Overlay>
                <ActionList selectionVariant="single">
                  <ActionList.Item
                    selected={currentActive === "all"}
                    onSelect={() => {
                      setCurrentActive("all");
                    }}
                  >
                    All
                  </ActionList.Item>
                  <ActionList.Item
                    selected={currentActive === "current"}
                    onSelect={() => {
                      setCurrentActive("current");
                    }}
                  >
                    Current
                  </ActionList.Item>
                  <ActionList.Item
                    selected={currentActive === "employment"}
                    onSelect={() => {
                      setCurrentActive("employment");
                    }}
                  >
                    Employment
                  </ActionList.Item>
                  <ActionList.Item
                    selected={currentActive === "internship"}
                    onSelect={() => {
                      setCurrentActive("internship");
                    }}
                  >
                    Internships
                  </ActionList.Item>
                  <ActionList.Item
                    selected={currentActive === "education"}
                    onSelect={() => {
                      setCurrentActive("education");
                    }}
                  >
                    Education
                  </ActionList.Item>
                  <ActionList.Item
                    selected={currentActive === "community_work"}
                    onSelect={() => {
                      setCurrentActive("community_work");
                    }}
                  >
                    Community Work
                  </ActionList.Item>
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          </Text>
        </Box>
      </Box>
      <Timeline>
        {career
          .filter(
            (item) =>
              item.show &&
              (currentActive === "all"
                ? true
                : currentActive === "current"
                ? item.current
                : item.type === currentActive)
          )
          .map((item, index) => {
            return item.current ? (
              <Timeline.Item key={index}>
                <Timeline.Badge sx={{ bg: "success.emphasis" }}>
                  <StyledOcticon
                    icon={FlameIcon}
                    sx={{ color: "fg.onEmphasis" }}
                  />
                </Timeline.Badge>
                <Timeline.Body>
                  <Box
                    display="grid"
                    gridTemplateColumns="0.25fr 2.25fr"
                    gridGap={3}
                  >
                    <Box>
                      <Text as="p">{item.date}</Text>
                    </Box>
                    <Box>
                      <Text as="p" mb={0}>
                        {item.title}
                      </Text>
                      <Text as="p" mt={0}>
                        {processString(config)(item.description)}
                      </Text>
                    </Box>
                  </Box>
                </Timeline.Body>
              </Timeline.Item>
            ) : (
              <Timeline.Item key={index} condensed>
                <Timeline.Badge>
                  <StyledOcticon icon={GitCommitIcon} />
                </Timeline.Badge>
                <Timeline.Body>
                  <Box
                    display="grid"
                    gridTemplateColumns="0.25fr 2.25fr"
                    gridGap={3}
                  >
                    <Box>
                      <Text as="p">{item.date}</Text>
                    </Box>
                    <Box>
                      <Text as="p" mb={0}>
                        {item.title}
                      </Text>
                      <Text as="p" mt={0}>
                        {processString(config)(item.description)}
                      </Text>
                    </Box>
                  </Box>
                </Timeline.Body>
              </Timeline.Item>
            );
          })}
      </Timeline>
    </>
  );
}
