import { IconButton, useTheme } from "@primer/react";
import { SunIcon, MoonIcon } from "@primer/styled-octicons";

export default function ThemeToggle() {
  const { setColorMode, colorMode } = useTheme();
  return (
    <IconButton
      aria-label="themeToggle"
      size="small"
      variant="invisible"
      icon={colorMode === "day" ? SunIcon : MoonIcon}
      onClick={() => setColorMode(colorMode === "day" ? "night" : "day")}
    />
  );
}
